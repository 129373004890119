import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
    <SEO title="Resources" />
    <div className="container">
      <div className="main-content__container">
        <div className="main-content__body">
          <h1 className="main-content__title">Resources</h1>
          <div className="main-content__content">{children}</div>
        </div>
      </div>
    </div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This version of the Staceyschlanger.work is built using `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{` and `}<a parentName="p" {...{
        "href": "https://mdxjs.com/"
      }}>{`MDX`}</a>{`. Social media icons are from `}<a parentName="p" {...{
        "href": "https://fontawesome.com/"
      }}>{`FontAwesome`}</a>{`.
I currently write in `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`Visual Studio Code`}</a>{` and use `}<a parentName="p" {...{
        "href": "https://www.gitkraken.com/invite/qRVJ6M3j"
      }}>{`GitKraken`}</a>{` as my Git GUI of choice.
I use a 2020 13" Macbook Pro for personal projects.
For my home "office," I additionally use:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lg.com/us/monitors/lg-29WK600-W-ultrawide-monitor"
        }}>{`29" UltraWide monitor by LG (29WK600-W)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.raindesigninc.com/mstand.html"
        }}>{`RainDesign mStand`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.logitech.com/en-us/product/mx-master-3"
        }}>{`Logitech Master MX mouse`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.caldigit.com/ts3-plus/"
        }}>{`CalDigit TS3 Plus Dock`}</a></li>
      <li parentName="ul">{`a mousepad I received with my copy of Jolin Tsai's 2009 album "`}<a parentName="li" {...{
          "href": "https://open.spotify.com/album/4vGWkGex9QdZCXwP5XM3HR?si=xB4iCqH3TO2UhmLU_trgag&dl_branch=1",
          "title": "Butterfly on Spotify"
        }}>{`Butterfly`}</a>{`"`}</li>
    </ul>
    <p>{`I also have an Apple Magic Keyboard but I `}<em parentName="p">{`kind of`}</em>{` hate it and do not recommend it. I did, however, spend a lot of money on it so I'm going to keep using it until one of us dies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      